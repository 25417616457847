<template>
    <div class="headerContainer flex-between">
        <div class="systemTitle">
            {{ loginUserName && loginUserName + "，" }}欢迎您
        </div>
        <div class="systemTool flex-y-center">
            <SnActionBtn
                :showNameList="['主页', '退出']"
                :nameDirection="false"
                :srcState="false"
            ></SnActionBtn>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
    name: "public-header",
    components: {},
    data() {
        return {
            systems: [],
        };
    },
    computed: {
        ...mapGetters(["userRealName"]),
        loginUserName() {
            return localStorage.userRealName;
        },
    },
    mounted() {},
    methods: {
        ...mapMutations([]),
    },
};
</script>

<style lang="scss" scoped>
.headerContainer {
    width: 100%;
    height: 30px;
    background-color: var(--headBgColor);
    padding: 0 20px;
    .systemTitle {
        text-align: left;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #fff;
        line-height: 30px;
    }
}
</style>
