import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "",
    components: {},
    props: {
        menus: {
            default() {
                return [];
            },
        },
        mainMenuIndex: {
            default() {
                return ""
            }
        }
    },
    data() {
        return {
            selectedId: 0,
            hoverIndex: 0,
            currentSystem: localStorage.currentSystem,
            selectMenu: [],
        };
    },
    computed: {
        ...mapGetters(["modulePower"]),
    },
    watch: {
        mainMenuIndex: {
            immediate: true,
            handler() {
                this.getMenus();
            },
        },
        selectedId: {
            immediate: true,
            handler() {
                if (this.selectedId !== undefined) {
                    this.setLocalData();
                }
            }
        }
    },
    methods: {
        ...mapActions(["getFuncTreeSecond"]),
        goHome() {
            this.$router.push({
                name: "home",
            });
        },
        setLocalData() {
            let current = this.selectMenu[this.selectedId];
            localStorage.setItem('requestModuleId', current && current.id || "");
            localStorage.setItem('requestModuleName', current && current.name || "");
        },
        changeMenu(menu, index) {
            if (this.selectedId === index) return false;
            this.selectedId = index;
            this.$router.push({
                name: menu.routerName,
            });
            this.$emit("changeMenu", menu, index);
        },
        getStyle(menu, state) {
            if (!menu.bgPicture) return;
            let bgPicture = require("@image/" + menu.bgPicture + ".png");
            if (menu.bgPicture1 && state) {
                bgPicture = require("@image/" + menu.bgPicture1 + ".png");
            }
            return `backgroundImage:url(${bgPicture})`;
        },
        mouseOver(index) {
            this.hoverIndex = index;
        },
        mouseOut() {
            this.hoverIndex = null;
        },
        async getMenus() {
            let res = await this.getFuncTreeSecond({
                funcId: this.mainMenuIndex,
            });
            const secordList = res.map((v) => v.ID);
            this.selectMenu = [];
            this.menus.forEach((v) => {
                if (secordList.includes(v.id)) {
                    this.selectMenu.push(v);
                }
            });
            this.getSelectID();
        },
        getSelectID() {
            let res = 0;
            const current = this.$router.currentRoute.name;
            // 先从当前的 selectMenu 搜索
            const findIndex = this.selectMenu.findIndex(
                (ele) => ele.routerName === current
            );
            if (findIndex < 0) {
                // 当前 selectMenu 没找到
                // 路由的配置
                const { routes } = this.$router.options;
                // 当前的路由列表
                const list = this.selectMenu.map(
                    ({ routerName }) => routerName
                );
                findCurrent(routes, list, current);
                this.selectedId = res;
            } else {
                // 当前 selectMenu 找到了
                this.selectedId = findIndex;
            }
            this.setLocalData();

            function findCurrent(routes, selectMenu, current) {
                // 遍历 路由配置
                routes.forEach((ele) => {
                    // 某路由配置 包含当前 路由列表
                    if (selectMenu.includes(ele.name)) {
                        // 该 嫌疑路由 是否在自己和自己的后代中可能包含当前路由
                        const child = findChild(ele.children || [], current);
                        if (child) {
                            res = selectMenu.indexOf(ele.name);
                        }
                    }
                    // 某路由配置 不包含当前 路由列表, 去 它的children里面找
                    else if (ele.children && ele.children.length > 0) {
                        findCurrent(ele.children, selectMenu, current);
                    }
                });
            }

            function findChild(children, current) {
                const temp = children.find((item) => item.name === current);
                if (temp) {
                    return true;
                } else {
                    const res = children.some((item) => {
                        return findChild(item.child || [], current);
                    });
                    return res;
                }
            }
        },
    },
};