<template>
    <div class="headerContainer flex-between">
        <img src="@image/header/header2/topBg.png" class="topBg" />
        <div class="flex-between-y-center logoPart">
            <img src="@image/header/icon/logo.png" class="logo" />
            <img src="@image/header/header2/name.png" class="name" />
        </div>
        <div class="menuPart flex-1 flex-y-center">
            <div
                v-for="(item, index) in systems"
                class="menuItem pointer flex-y-center"
                :class="[lyjActiveNum == index ? 'menuItemActive' : '']"
                :key="index"
                @click="goItem(item, index)"
            >
                <img :src="item.src" alt="" class="icon" />
                <div class="name">
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="actionPart">
            <div class="systemTitle">
                {{ time }} {{ loginUserName && loginUserName + "，" }}欢迎您!
            </div>
            <div class="systemTool flex-end">
                <SnActionBtn
                    :showNameList="['退出']"
                    :nameDirection="false"
                    :srcState="false"
                ></SnActionBtn>
            </div>
        </div>
    </div>
</template>

<script>
import info_mixin from "./info_mixin";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    mixins: [info_mixin],
    name: "lyj",
    components: {},
    props: {},
    data() {
        return {
            systems: [],
        };
    },
    computed: {
        ...mapGetters([
            "lyjActiveNum",
            "lyj_sjgl_state",
            "lyj_work_state",
            "lyj_inspect_state",
        ]),
    },
    watch: {},
    mounted() {
        this.getModulePower();
    },
    methods: {
        ...mapMutations({
            SET_LYJ_COMMON: "SET_LYJ_COMMON",
        }),
        // 获得功能权限
        getModulePower() {
            let powerList =
                (this.modulePower && JSON.parse(this.modulePower)) ||
                (localStorage.modulePower &&
                    JSON.parse(localStorage.modulePower)) ||
                [];
            this.systems = [];
            this.listAry.forEach((v) => {
                let item = powerList.find((val) => val.id === v.ID);
                if (item) {
                    this.systems.push(v);
                }
            });
            // this.turnSystem(this.systems[0]);
        },
        turnSystem(system) {
            let routeName = system.routeName;
            let systemName = system.name;
            if (system.toOtherSystem) {
                window.open(routeName);
            } else {
                let name = systemName;
                let routerList = [];
                routerList.push({ path: routeName, name: name });
                localStorage.routerList = JSON.stringify(routerList);
                localStorage.currentSystem = name;
                this.$router.push({
                    name: routeName,
                });
            }
        },
        goItem(item, index) {
            this.SET_LYJ_COMMON({
                lyjActiveNum: index,
            });
            this.$router.push({
                name: item.routeName,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.headerContainer {
    width: 100%;
    height: 80px;
    background-color: var(--headBgColor);
    padding: 0 20px;
    position: relative;
    .topBg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }
    .logoPart {
        margin-right: 40px;
        .logo {
            width: 71px;
            height: 68px;
            margin-right: 10px;
        }
    }

    .menuPart {
        position: relative;
        z-index: 2;
        .menuItem {
            padding: 0 10px;
            height: 100%;
            margin-right: 10px;
            &:nth-last-child(1) {
                margin-right: 0;
            }
            &:hover {
                background: rgba(24, 109, 112, 0.8);
            }
            .icon {
                width: 22px;
                height: 22px;
                margin-right: 6px;
            }
            .name {
                color: #cae6e3;
                font-size: 22px;
            }
        }
        .menuItemActive {
            background: rgba(24, 109, 112, 0.8);
        }
    }
    .actionPart {
        position: relative;
        z-index: 2;
        .systemTitle {
            text-align: left;
            padding-left: 20px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
            color: #fff;
            line-height: 30px;
        }
        .systemTool {
            margin-top: 10px;
        }
    }
}
</style>
