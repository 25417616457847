// 通用左侧导航菜单
<template>
    <div id="left-menu">
        <div class="system-logo flex-both-center">
            <span>{{ currentSystem }}</span>
        </div>
        <div class="system-menu scrollbar-hidden">
            <div
                v-for="(menu, index) in selectMenu"
                :key="index"
                class="menu"
                :class="{ current: selectedId === index }"
                @click="changeMenu(menu, index)"
            >
                <div class="menu-bg"></div>
                <div
                    class="menu-content"
                    @mouseover="mouseOver(index)"
                    @mouseout="mouseOut()"
                >
                    <div
                        class="menu-icon iconfont"
                        :class="[
                            menu.className,
                            selectedId === index ? 'menu-title-current' : '',
                        ]"
                        :style="
                            getStyle(
                                menu,
                                selectedId === index || hoverIndex === index
                            )
                        "
                    ></div>
                    <div
                        class="menu-title"
                        :class="{ 'menu-title-current': selectedId === index }"
                    >
                        {{ menu.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import info_mixin from "./info_mixin";
export default {
    mixins: [info_mixin],
    name: "",
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
#left-menu {
    width: 90px;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    background-color: var(--leftMenuBgColor);
    .system-logo {
        flex: 0 0 100px;
        background: url("~@image/menu/left_menu_bg.png") no-repeat;
        background-size: 100% 100%;
        // background-color: #182987;
        margin-bottom: 10px;
        span {
            color: rgba(255, 255, 255, 1);
            font-size: 24px;
            font-weight: 500;
            width: 28px;
            display: block;
            margin: 30px 0;
        }
    }

    .system-menu {
        flex: auto;

        .menu {
            height: 85px;
            position: relative;
            .menu-bg {
                width: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                height: 0;
                background-color: #fff;
                border-right: 4px solid #186d70;
                animation: heightChangeToHide 0.3s linear 0s forwards;
            }
            &.current,
            &:hover {
                .menu-bg {
                    animation: heightChangeToShow 0.3s linear 0s forwards;
                }
            }
            .menu-content {
                width: 100%;
                height: 100%;
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-right: 4px solid transparent;
                position: relative;
                z-index: 1;
                .menu-icon {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    font-size: 38px;
                    color: #89a0a1;
                }
                &.current,
                &:hover {
                    .menu-icon {
                        color: #186d70;
                    }
                }

                .menu-title {
                    height: 30px;
                    line-height: 30px;
                    color: #89a0a1;
                    font-size: 14px;
                }
                &.current,
                &:hover {
                    .menu-title {
                        color: #186d70;
                    }
                }
                .menu-title-current {
                    color: #186d70;
                }
            }
        }
    }
}
</style>
