<template>
    <div class="headerPart">
        <header1 v-if="system1"></header1>
        <header2 v-if="system2"></header2>
        <header3 v-if="system3"></header3>
    </div>
</template>

<script>
import header1 from "./header/header1.vue"; //文物保护监测预警平台
import header2 from "./header/header2.vue"; //林业监测
import header3 from "./header/header3.vue"; //茶特中心
import { mapGetters } from "vuex";
export default {
    name: "",
    components: {
        header1,
        header2,
        header3,
    },
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters(["system"]),
        system1() {
            return this.system === "system1";
        },
        system2() {
            return this.system === "system2";
        },
        system3() {
            return this.system === "system3";
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.headerPart {
}
</style>
