import { mapGetters, mapMutations } from "vuex";
export default {
    name: "public-header",
    components: {},
    data() {
        return {
            time: "",
            systems: [],
            listAry: [
                {
                    id: "10",
                    name: "工作台面",
                    src: require("@image/header/icon/gztm.png"),
                    routeName: "basicTable",
                },
                {
                    id: "20",
                    name: "巡查管理",
                    src: require("@image/header/icon/xcgl.png"),
                    routeName: "monitoringcloud",
                },
                {
                    id: "93",
                    name: "数据管理",
                    src: require("@image/header/icon/sjgl.png"),
                    routeName: "monitoringdata",
                },
                {
                    id: "94",
                    name: "报告管理",
                    src: require("@image/header/icon/sjgl.png"),
                    routeName: "patrolReport",
                },

            ],
        };
    },
    props: {},
    computed: {
        ...mapGetters(["userRealName"]),
        loginUserName() {
            return localStorage.userRealName;
        },
    },
    mounted() {
        setInterval(() => {
            let str = this.common.getTimeYmdhms();
            this.time =
                str.slice(0, 4) +
                "年" +
                str.slice(5, 7) +
                "月" +
                str.slice(8, 10) +
                "日" +
                str.slice(11);
        }, 1000);
    },
    methods: {
        ...mapMutations([]),

    },
};