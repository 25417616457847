<template>
    <div class="menuPart">
        <menu1
            v-if="system1"
            :menus="menus"
            :mainMenuIndex="mainMenuIndex"
            @changeMenu="changeMenu"
        ></menu1>
        <menu2
            v-if="system2"
            :menus="menus"
            :mainMenuIndex="mainMenuIndex"
            @changeMenu="changeMenu"
        ></menu2>
        <menu3
            v-if="system3"
            :menus="menus"
            :mainMenuIndex="mainMenuIndex"
            @changeMenu="changeMenu"
        ></menu3>
    </div>
</template>

<script>
import menu1 from "./left_menu/menu1.vue"; //文物保护监测预警平台 左侧
import menu2 from "./left_menu/menu2.vue"; //林业监测
import menu3 from "./left_menu/menu3.vue"; //茶叶中心
import { mapGetters } from "vuex";
export default {
    name: "",
    components: {
        menu1,
        menu2,
        menu3,
    },
    props: {
        menus: {
            default() {
                return [];
            },
        },
        mainMenuIndex: {
            default() {
                return "";
            },
        },
    },
    computed: {
        ...mapGetters(["system"]),
        system1() {
            return this.system === "system1";
        },
        system2() {
            return this.system === "system2";
        },
        system3() {
            return this.system === "system3";
        },
    },
    methods: {
        changeMenu(menu, index) {
            this.$emit("changeMenu", menu, index);
        },
    },
};
</script>

<style lang="scss" scoped></style>
